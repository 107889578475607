import React from 'react';
import {Button,Dialog,DialogActions,DialogContent
        ,DialogTitle,Typography} from '@material-ui/core';
import { makeStyles ,withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    //margin: theme.spacing(0),
    marginTop: 0,
    paddingTop:0,
    position: 'relative',
  },
     
}));

export default function ModalInformativo({titulo,subtitulo,children,modalAbierto,setModalAbierto,tamanio,colorFondo}) {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');      
  let letColor =process.env.REACT_APP_Color_Second

  const cerrarBorrar = () => {
    
    setOpen(false);
   // setAviso(false);
    setModalAbierto(false)
  };
  const handleClose = () => {
    setOpen(false);
    setModalAbierto(false)
  };
  const handleCloseSN = () => {
    //setAviso(false);
    //setModalAbierto(false)
  };
  
  const classes = useStyles();
  //const [espera,setEspera]=React.useState(false);
  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (modalAbierto) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }    
  }, [modalAbierto]);
  
  return (
  <div>         
    <Dialog
      open={modalAbierto}
      //onClose={handleClose}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth={true}
      maxWidth={tamanio}
    >
                
      <DialogTitle style={{color:letColor}} id="scroll-dialog-title" 
        align="center"    onClose={handleClose}
      >
        {titulo}
        {subtitulo ? <div style={{color:'black', fontSize:17}}>{subtitulo}</div> : null}
      </DialogTitle>
            
      <DialogContent dividers={scroll === 'paper'} style={{backgroundColor:colorFondo === undefined ? null : '#f5f5f5'}} >        

        {children}        

      </DialogContent>
    
    {/*   <DialogActions>
        <Button onClick={cerrarBorrar}  color="secondary" variant="outlined">
          Cerrar
        </Button>      
      </DialogActions> */}
      
    </Dialog>    
  </div>
  );
}