// Injects the Facebook SDK into the page

/*global FB*/

const injectFbSDKScript = () => {

    // Espera a inicializar el sdk antes de la app
    window.fbAsyncInit = function () {
        window.FB.init({
            appId: 564332095062073, cookie: true, xfbml: true, version: 'v13.0'
        });
    };

    (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
            FB.XFBML.parse();
            return;
        }
        js = d.createElement(s);
        js.id = id;
        js.async = true;
        js.src = "https://connect.facebook.net/es_LA/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'))
};

export default injectFbSDKScript;

/* const injectFbSDKScript = () => {
    (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
            const FB = window.FB;
            FB.XFBML.parse();
            return;
        }
        js = d.createElement(s); js.id = id;
        js.async=true
        js.src = "https://connect.facebook.net/es_LA/sdk.js#xfbml=1&version=v13.0&appId=306664281638708&autoLogAppEvents=1";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'))
}; */