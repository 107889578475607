import React,{useEffect} from 'react';
import { makeStyles ,withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import { green } from '@material-ui/core/colors';
import Alert from '@material-ui/lab/Alert';
import {Button, Dialog, DialogActions,Box,
  DialogContent, DialogTitle, CircularProgress} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(3),
    //marginBottom: theme.spacing(1),
    justifyContent:"center",
    //display:"flex",
    //width: "100%",
    
  },
  wrapper: {    
    marginTop: 0,
    paddingTop:0,
    position: 'relative',
  },
  buttonGuardar:{backgroundColor:'#0ECD5F',color:'white',
  '&:hover': {
    backgroundColor: '#0ECD5F',
      borderColor: '#0062cc',
      boxShadow: 'none',
  },
},
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));


export default function ModalStepperBack({contenidoStep,getSteps,mostraMensaje,cargandoNom,
                                                titulo,modalAbierto,setModalAbierto,cargaPefil,
                                                guardarDatos,mensaje, tipoAdvertencia,aviso, activeStep, setActiveStep,
                                                setAviso, tamanio,btnDeshabilitado,cargando,validaPasos,btnVerCerrar}) 
{
  const classes = useStyles();
  
  //const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();
  const [scroll, setScroll] = React.useState('paper');   
  /*const isStepOptional = (step) => {
    return step === 1;
  };*/
  const descriptionElementRef = React.useRef(null);
  const handleClose = () => {
    //  setOpen(false);
      setModalAbierto(false)
    };
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const handleCloseSN = () => {
    setAviso(false);    
  };
  useEffect(() => {
    if (modalAbierto) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    } 
}, [modalAbierto]);
  const handleNext = () => {
    let newSkipped = skipped;    
    let respuesta=validaPasos(activeStep)    
    if (respuesta) 
    {
      let auxRes=isStepSkipped(activeStep)      
      if (auxRes) 
      {console.log("cambiando");
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
        
      }  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
    
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  /*const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("No puedes saltar esta seccion ,no es opcional.");
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };*/

  const handleReset = () => {
    setActiveStep(0);
  };
  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  let fondo=process.env.REACT_APP_Fondo_Color
  return (
  <div className={classes.root}>
    <Dialog open={modalAbierto} //onClose={handleClose} 
      scroll={scroll} aria-labelledby="titulo" aria-describedby="descripcion"
      fullWidth={true} maxWidth={"sm"}
    >
      <DialogTitle style={{color:fondo}} id="titulo" align="center" 
        onClose={handleClose}
      >
        {titulo}
      </DialogTitle>      
      <div>
      {activeStep === steps.length ? 
        ( <div>
            <Typography className={classes.instructions}>
              Todos los pasos - deben ser terminados.
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reiniciar
            </Button>
          </div>
        ) : 
        ( <div>
          <DialogContent dividers={scroll === 'paper'}  id="contenido-modal" style={{paddingLeft:0.7+'rem', paddingRight:0.7+'rem'}}>        
           
            <Stepper activeStep={activeStep} style={{padding:0,margin:0}}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};             
                if (isStepSkipped(index)) 
                { stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <Box className={classes.instructions} >
            { aviso && tipoAdvertencia && mensaje.length!==0 ? 
              <Box mb={2} >
              <Alert severity={tipoAdvertencia}  onClose={handleCloseSN}>{mensaje}</Alert>
              </Box>
              :<span></span>
            }
              {contenidoStep(activeStep)}
              {mostraMensaje[activeStep] && <Box display="flex" justifyContent={"flex-end"} mt={2}>
                <Typography variant='body'>*Campo obligatorio.</Typography>
              </Box>}
            </Box>
          </DialogContent>            
          <div>
            <DialogActions>              
             {!btnVerCerrar? <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                Regresar
              </Button>:<Button  onClick={handleClose} className={classes.button}>
                Cerrar
              </Button>       }       
              {activeStep === steps.length - 1 ?
                <div className={classes.wrapper}>
                  <Button
                    variant="contained" className={classes.buttonGuardar}
                    disabled={btnDeshabilitado ? true:cargando}
                    onClick={guardarDatos}
                  >
                    Guardar
                  </Button>
                  {cargando && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div> : 
                <Button
                  variant="contained"   color="primary" 
                  disabled={cargandoNom ? cargandoNom :cargaPefil}
                  onClick={handleNext} className={classes.button}
                >
                  {'Siguiente'}
                </Button>
              }
            </DialogActions>                
          </div>
        </div>)
      }
      </div>
    </Dialog>  
  </div>
  );
}
