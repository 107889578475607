import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AuthProvider  from './componentes/context/AuthContext';
import GeneralesProvider from './componentes/context/GeneralesContext';
import Login from './componentes/auth/Login'
import { generarTema } from "./componentes/layout/Tema";
import { ToastContainer } from "react-toastify";
import Contenedor from "./componentes/layout/Contenedor";
//import PrincipalStepper from './componentes/VentanasConStepper/Pantalla'
import PantallaFb from './componentes/VentanasConStepper/PantallaFB'
import VentanaAuxiliar from "./componentes/ventanaAuxiliar";
function App() {
  const [colorTema, setColorTema] = React.useState(false);

  React.useEffect(() => {
    const auxColor = localStorage.getItem("ColorTheme");
    if (auxColor === undefined || auxColor === null) {
      localStorage.setItem("ColorTheme", colorTema);
      //console.log("sin el tema");
    } else {
      //console.log("cargando el tema");
      //console.log(JSON.parse(auxColor));
      setColorTema(JSON.parse(auxColor));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
  <React.Fragment>
    <AuthProvider>
      <GeneralesProvider>
      <Router>        
        <ThemeProvider theme={generarTema(colorTema)}>
          <Contenedor colorTema={colorTema} setColorTema={setColorTema} >
            <Switch>
              {/* <Route exact path="/" component={Login} />     */}
              <Route exact path="/" component={VentanaAuxiliar} />    
              {/* <Route exact path="/" component={PrincipalStepper} />   */}   
              {/* <Route exact path="/stepper" component={PrincipalStepper} />      */}
              <Route exact path="/prueba-fb" component={PantallaFb} />     
              <Route exact path="/entrada" component={PantallaFb} />    
              <Route path="*" component={  Login } />               
            </Switch>      
          </Contenedor>
          <ToastContainer autoClose={5000} closeOnClick />
        </ThemeProvider>        
      </Router>
      </GeneralesProvider>
    </AuthProvider>
  </React.Fragment>
  );
}

export default App;
