import React from "react";
import { Box,  Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Principal from "./Principal";
import VideoJS from "../generales/VideoJS";
import ContactoRedes from "../generales/ContactoRedes";
const useStyles = makeStyles((theme) => ({
  texto: {    
    "-webkit-text-stroke": "2px #fff",
    color: "#fff",
    fontWeight: 650,
    fontSize: 3.5 + "em",
    width: 80+'%',
  },
  textoFooter: {    
    //"-webkit-text-stroke": "2px #e83c46",
    color: "#fff",
    //fontWeight: 650,
    fontSize: 1.5 + "em",
    width: 80+'%',
  },
  leyenda: {
    color: "#fff",
    fontWeight: 600,
  },
  imgColage: {
    minwidth: "28rem",
    maxWidth: "31rem",
    minHeight: "31rem",
    maxHeight: "35rem",
   // marginTop: 5 + "rem",
    paddingLeft: 2 + "rem",
  },
}));

const VentanaWeb = () => {
  const classes = useStyles();
  let auxAnch = 93.5 + "rem";
  //let auxAnch = 100 + "vw";
  let limiteAnch=1400
  let anchoTamMax=1400
//  console.log(window.innerHeight);
  const auxTam = window.innerWidth < limiteAnch ? auxAnch : "100vw";
   let auxAlt = window.innerHeight < 700 ?  40+'rem': "100vh";
  //const auxAlt =  "100vh";
  let altuTamMax=700

  const playerRef = React.useRef(null);

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    
    sources: [{
      src: 'https://social-whatsapp.daduva.mx/publico/Morena2021.mp4',
      type: 'video/mp4'}
    ]
  };
  
  const [tamWidth, setTamWidth] = React.useState({
    anchura: auxTam,
    altura: auxAlt,
  });
  React.useEffect(() => {
    let auxTam2 = window.innerWidth < limiteAnch ? auxAnch : "100vw";
    setTamWidth({ ...tamWidth, anchura: auxTam2 });
  }, [window.innerWidth]);

  React.useEffect(()=>{
    window.addEventListener('resize',ajustarTam );
    
     return()=>{ window.removeEventListener('resize', ajustarTam)}
  },[window.innerHeight])

  const ajustarTam=(params)=> {
    if(window.innerHeight<altuTamMax)
      { console.log(window.innerHeight);
        console.log(auxAlt);
        setTamWidth({...tamWidth,altura:auxAlt})
      }
      else
      { setTamWidth({...tamWidth,altura:auxAlt})
      }

      if(window.innerWidth<anchoTamMax)
      { if(window.innerWidth>=980 && window.innerWidth<1663)
        { if(window.innerWidth>=1400 && window.innerWidth<1663)
          { setTamWidth({...tamWidth,anchura:auxAnch})
          }
          else
          { setTamWidth({...tamWidth,anchura:auxAnch})
          }
        }
        else
        { setTamWidth({...tamWidth,anchura:auxAnch})
        }      
      }
      else
      { setTamWidth({...tamWidth,anchura:"100vw"})
      }
  }
  const fondo = "./img/fondoLimpio.jpg";      
  let imgRV = "./img/EG1.png";    
  const handlePlayerReady = (player) => {
    playerRef.current = player;
    // You can handle player events here, for example:
    player.on('waiting', () => {
      player.log('player is waiting');
    });

    player.on('dispose', () => {
      player.log('player will dispose');
    });
  };

/* const MostrarVideo2=(link)=>{
  return( 
  <video width="400" controls>
    <source src={link} type="video/mp4"/>    
  </video>
  )
} */

  return (
    <div
      style={{
        width: "100%",
        height: tamWidth.altura,
        backgroundImage: `url(${fondo})`,
        // backgroundColor:"#7F94C6",
        //  background: "linear-gradient(90deg, rgba(2,0,36,1) 11%, rgba(127,148,198,1) 62%, rgba(127,148,198,1) 87%)",
        "background-size": "100% 100%",
      }}
    >
      <Box
        display={"flex"}
        row
        alignItems="center"
        width={"100%"}
        height="100%"
      >
        <Box
          display={"flex"}
          width={31 + "rem"}
          maxHeight={"35rem"}
          justifyContent="center"
          style={{ alignItems: "center" }}
        >
          {/* <img src={fotos} className={classes.imgColage}  title="dgadigital Fotos" alt=" dgadigital Fotos" /> */}
          <div
            className="fb-page"
            data-href="https://www.facebook.com/RedMorenaNacional"
            data-tabs="timeline"
            data-width="350"
            data-height=""
            data-small-header="false"
            data-adapt-container-width="true"
            data-hide-cover="false"
            data-show-facepile="true"
            style={{ width: 100 + "%", marginLeft: 2.5 + "rem" }}
          >
            <blockquote
              cite="https://www.facebook.com/RedMorenaNacional"
              className="fb-xfbml-parse-ignore"
            >
              <a href="https://www.facebook.com/RedMorenaNacional">RedMorena</a>
            </blockquote>
          </div>
        </Box>
        <div
          //display={"flex"} flexDirection="column"
          style={{
            //alignItems: "center",
            textAlign: "center",marginLeft: 3.5 + "rem",
          }}
          minWidth={"24rem"}
        >
          <Box mb={5}
            display={"flex"} flexDirection="column" justifyContent="center"
            style={{
              alignContent: "center",
              textAlign: "center",
              justifyContent:"center",
              //marginLeft: 3.5 + "rem",
              width: 25+'rem',
            }}
          >
            <Box>
            <Typography
              variant="body" color="primary" className={classes.texto}
            >
              BIENVENIDOS  
            </Typography>
            </Box>
            

            <Box display="flex"  justifyContent="center">
              <Box width={"90%"}>
              <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
              </Box>
            </Box>
            <ContactoRedes />
          </Box>
          <Box display={"flex"} alignContent={"flex-end"} justifyContent="center" >
            <Typography
              variant="body" color="initial" className={classes.textoFooter}
            >
              SOMOS CIUDADANOS ORGANIZADOS 
            </Typography>
          </Box>
        </div>
        <Box
          display={"flex"}
          style={{ alignItems: "center", marginLeft: 2 + "rem" }}
          minWidth={24 + "rem"}
          flexDirection={"column"}
          justifyContent="center"
        >
          <Box
            display={"flex"}
            // style={{ marginTop: 10 + "rem" }}
            flexDirection={"column"}
          >
 
         
        {/*     <img
              src={dgadigital}
              title="Logo dgadigital"
              alt="Logo dgadigital"
              style={{ width: 20 + "rem", height: 12 + "rem" }}
            /> */}
          </Box>

          <Box display={"flex"}>
            <Principal />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default VentanaWeb;
