import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Principal from "./PrincipalBtnMovil";
import VideoJS from "../generales/VideoJS";
import ContactoRedes from '../generales/ContactoRedes'
const useStyles = makeStyles((theme) => ({
  texto: {
    //"text-shadow": "0 0 2px red",
    //"-webkit-text-stroke": "1.5px #af272f",
    color: "#fff",
    fontWeight: 600,
    //fontSize: 1.8 + "em",
    textAlign:"center"
  },
  textoFooter: {    
    color: "#fff",  
    fontSize: 1.2 + "em",
    width: 80+'%',
  },
  leyenda: {
    color: "#fff",
    fontWeight: 600,
    fontSize: 2 + "em",
    //width: 16+'rem'
  },
  imgColage: {
    minwidth: "11rem",
    maxWidth: "14rem",
    minHeight: "14rem",
    maxHeight: "18rem",
   // marginTop: 5 + "rem",
  },
}));

const VentanaMovil = () => {
  const classes = useStyles();
  //let auxAnch = 93.5 + "em";
  let auxAnch = 100 + "vw";
  let limiteAnch=1300
  const auxTam = window.innerWidth < limiteAnch ? auxAnch : "100vw";
  //const auxAlt = window.innerHeight < 700 ? 41.3 + "rem" : "100vh";
  const auxAlt =  "200vh";
  const [tamWidth, setTamWidth] = React.useState({
    anchura: auxTam,
    altura: auxAlt,
  });
  
  const playerRef = React.useRef(null);

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [{
      src: 'https://social-whatsapp.daduva.mx/publico/Morena2021.mp4',
      type: 'video/mp4'
    }]
  };
  
  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on('waiting', () => {
      player.log('player is waiting');
    });

    player.on('dispose', () => {
      player.log('player will dispose');
    });
  };
  
  React.useEffect(() => {
    let auxTam2 = window.innerWidth < limiteAnch ? auxAnch : "100vw";
    setTamWidth({ ...tamWidth, anchura: auxTam2 });
  }, [window.innerWidth]);

  let fondo = "./img/fondoLimpio.jpg";  
  let imgRV = "./img/EG1.png";  

  return (
    <div
      style={{
        width: tamWidth.anchura,
        height: auxAlt,
        backgroundImage: `url(${fondo})`,
        "background-size": "100% 100%",
      }}
    >
      <Box display={"flex"} flexDirection="column"  width={"100%"} height="100%">
        
        <Box
          display={"flex"}
          mt={2} mb={4}
          flexDirection="column"
           justifyContent="center" 
          style={{
            textAlign: "center",
          }}
          
        >
          
          <Typography variant="h5" color="initial" className={classes.texto}>
            BIENVENIDOS 
          </Typography>
          <ContactoRedes/>
        </Box>
        <Box display={"flex"} justifyContent="center" mb={5} //minWidth={13 + "rem"} 
        >
 
          <Box width={80+'%'}>
          <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
          </Box>
          
        </Box>
        <Box
          display={"flex"}
          style={{ alignItems: "center"}}
          minWidth={20 + "rem"} 
          justifyContent="center"
          flexDirection={"column"}
        >
          <Box
            display={"flex"}                 
            flexDirection={"column"}
            justifyContent="center"
          >

            <Box display={"flex"} justifyContent="center">
          {/*   <img
              src={dgadigital} title="Logo dgadigital" alt="Logo dgadigital"
              style={{ width: 18 + "rem", height: 8 + "rem" }}
            /> */}
            </Box>
            
          </Box>
         {/*  <Box display={"flex"}  mt={1}>
            <Principal />
          </Box> */}
          
          <Box display={"flex"} alignContent={"flex-end"} justifyContent="center" mt={5} >
            <Typography
              variant="body" color="initial" className={classes.textoFooter}
            >
              SOMOS CIUDADANOS ORGANIZADOS
            </Typography>
          </Box>
          <Box display={"flex"} alignContent={"flex-start"}  justifyContent="center" width="80%" height={"32rem"} >
          <div className="fb-page" data-href="https://www.facebook.com/RedMorenaNacional" data-tabs="timeline"
            data-width="500" data-height="580" data-small-header="false" data-adapt-container-width="true"
            data-hide-cover="false" data-show-facepile="true" style={{width:100 + "%",marginTop:2.5+'rem'}}
          >
            <blockquote cite="https://www.facebook.com/RedMorenaNacional" 
              className="fb-xfbml-parse-ignore">
              <a href="https://www.facebook.com/RedMorenaNacional">
                Morena
              </a>
            </blockquote>
        </div>
          </Box>
          
          
        </Box>
      </Box>
    </div>
  );
};

export default VentanaMovil;
