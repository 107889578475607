import React from "react";
import axios from "axios";
import qs from "qs";
import {
  Box,
  Button, // Hidden, Paper,
} from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
//import SoloImg from "./SoloImg";
//import NuevoPerfil from "./NuevoPerfil";
//import SugerenciaModal from "../Sugerencia/index";
import SugerenciaModal from "../Sugerencia/ModalSugeStepper";
//import ModalNvoPerfil from "./nvoPerfil/ModalNvoPerfilMovil";
import ModalNvoPerfil from "../nvoPerfilStepper/ModalPerfilStepper";
import ModalNvoPerfilImg from "../nvoPerfil/ModalNvoImgMovil";
import ModalCompartirFB from "../fb-share/ModalCompartirFB";
function BotonMovil() {
  const source = axios.CancelToken.source();
  const [listMuni, setListMuni] = React.useState([]);
  const [plantilla, setPlantilla] = React.useState([]);
  const [modalAbierto, setModalAbierto] = React.useState(false);
  const [modalNvoPerfil, setModalNvoPerfil] = React.useState(false);
  const [nvoRegisPerfil, setNvoRegisPerfil] = React.useState([]);
  const [modalNvoImg, setModalNvoImg] = React.useState(false);
  const [modalAbiertoFB, setModalAbiertoFB] = React.useState(false);
  const [perfilCompartir,setPerfilCompartir] = React.useState([]);//st// ate del url img

  React.useEffect(() => {
    let auxCode = uuidv4();
    let auxRecorte = auxCode.slice(
      auxCode.lastIndexOf("-") + 1,
      auxCode.length
    );
    //let Dispvo =localStorage.getItem('Dispvo') ;
    //let auxDispV =Dispvo.length >= 12 ? Dispvo:'45vf2d1vdf21d2f'
    let auxDispV = auxRecorte.length >= 12 ? auxRecorte : "45vf2d1vdf21d2f";
    localStorage.setItem("Dispvo", auxDispV);
    const llenaListMuni = () => {
      let data = qs.stringify({
        estado: "15",
        idDispositivo: auxDispV,
      });
      let url = "lugar-municipio-tipo";
      llamadaApi(data, url, setListMuni);
    };

    const llenaPlantilla = () => {
      let data = qs.stringify({
        idDispositivo: auxDispV,
      });
      let url = "plantilla-base";
      llamadaApi(data, url, setPlantilla);
    };

    llenaPlantilla();
    llenaListMuni();
  }, []);

  async function llamadaApi(data, url, setValue) {
    let config = {
      method: "post",
      url: process.env.REACT_APP_ENLACE_PAG + url,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
      cancelToken: source.token,
    };
    await axios(config)
      .then(function (response) {
        let auxiliar = response.data;
        //console.log(auxiliar);
        if (auxiliar.length > 0) {
          setValue(auxiliar);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const AbrirModalSugerencia = () => {
    setModalAbierto(true);
  };

  function abrirModalNvoPerfil() {
    setModalNvoPerfil(true);
  }
  function abrirModalNvoPerfilImg() {
    //console.log(listPlantilla);
     //let auxNom="Fernando Carlos Martinez Perez"
    // setNvoRegisPerfil({Nom:auxNom,llave:1})
    setTimeout(() => {
      //console.log('cargando modal img')
      setModalNvoImg(true);
    }, 300);
    //setModalNvoImg(true);
  }

/*   function abrirModalCompartir() {    
    setPerfilCompartir({url:"./img/plantilla.jpg"})
    setModalAbiertoFB(true);
   }
  */
   function abrirModalCompartir2(link) {    
     setPerfilCompartir({url:link})
     setTimeout(() => {      
       setModalAbiertoFB(true);
     }, 300);      
   }
/*   const btnPruebaFb =<Box>
    <Button
      onClick={abrirModalCompartir} color="primary"
      style={{ margin: 3 + "rem" , width: 8.5+ "rem" }}
      variant="contained"              
    >
      COMPARTIR
    </Button>           
  </Box>  */

  const modalPerfil =
    modalNvoPerfil && plantilla.length !== 0 && listMuni.length !== 0 ? (
      <ModalNvoPerfil
        modalAbierto={modalNvoPerfil}
        setModalAbierto={setModalNvoPerfil}
        titulo="Registrate"
        listMun={listMuni}
        listPlantilla={plantilla}
        setNvoRegisPerfil={setNvoRegisPerfil}
        abrirModalNvoPerfilImg={abrirModalNvoPerfilImg}
      />
    ) : null;
  const modNvoImg =
    modalNvoImg && plantilla.length !== 0 ? (
      <ModalNvoPerfilImg
        modalAbierto={modalNvoImg}
        setModalAbierto={setModalNvoImg}
        titulo={nvoRegisPerfil.Nom ? "Registro " + nvoRegisPerfil.Nom : "Registro"}
        auxPerfImg={plantilla} nvoRegisPerfil={nvoRegisPerfil} 
        abrirModalCompartir2={abrirModalCompartir2} //actualizar={actualizar} setActualizar={setActualizar}
      />
    ) : null;
  const modalSug =
    modalAbierto && listMuni.length !== 0 ? (
      <SugerenciaModal
        listMun={listMuni}
        modalAbierto={modalAbierto}
        setModalAbierto={setModalAbierto}
      />
    ) : null;
   
    const modalFb = modalAbiertoFB && perfilCompartir.length !== 0 ?
      <ModalCompartirFB
        modalAbierto={modalAbiertoFB} setModalAbierto={setModalAbiertoFB}
        titulo={nvoRegisPerfil.Nom ? "Registro " + nvoRegisPerfil.Nom : "Registro"} 
        perfilCompartir={perfilCompartir}
      /> : null

  return (
    <React.Fragment>
      
      <Box
        display={"flex"}
        justifyContent={"center"}
       // height="100vh"
        alignItems={"center"}
        id="caja-grande"
        flexDirection="column"
      >
         { modalFb /* */}
        <Box display={"flex"} flexDirection="row">         
          {modalSug}
          {modalPerfil}
          {modNvoImg}
        </Box>
        <Box mt={2}>
          <Button
            onClick={AbrirModalSugerencia}
            variant="contained"
            style={{ width: 7+ "rem" }}
            color="secondary"
          >
            CONTACTAME
          </Button>
          <Button
            onClick={abrirModalNvoPerfil}
            style={{ marginLeft: 2 + "rem" , width: 7+ "rem" }}
            variant="contained"
            color="primary"
          >
            REGISTRATE
          </Button>
          { /* btnPruebaFb    */}  
         {/*  <Button onClick={abrirModalNvoPerfilImg}
        style={{marginLeft:1+'rem'}}
        variant="contained" color="primary"
      >
        Probando Perfil
      </Button>  */}
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default BotonMovil;
