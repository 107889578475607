import React,{useState,useEffect} from 'react'

const VentanaAuxiliar = () => {
    const fondo = "./EXPRESION-HD-LOGO.png";
    const limiteAnch=1400
    const anchoTamMax=1400  
    const auxAnch = 93.5 + "rem";
    const altuTamMax=700
    const auxTam = window.innerWidth < limiteAnch ? auxAnch : "100vw";
    const auxAlt = window.innerHeight < 700 ?  40+'rem': "100vh";
    const [tamWidth, setTamWidth] = useState({
        anchura: auxTam,
        altura: auxAlt,
      });
      useEffect(() => {
        let auxTam2 = window.innerWidth < limiteAnch ? auxAnch : "100vw";
        setTamWidth({ ...tamWidth, anchura: auxTam2 });
      }, [window.innerWidth]);
    
      useEffect(()=>{
        window.addEventListener('resize',ajustarTam );
        
         return()=>{ window.removeEventListener('resize', ajustarTam)}
      },[window.innerHeight])
    
      const ajustarTam=(params)=> {
        if(window.innerHeight<altuTamMax)
          { console.log(window.innerHeight);
            console.log(auxAlt);
            setTamWidth({...tamWidth,altura:auxAlt})
          }
          else
          { setTamWidth({...tamWidth,altura:auxAlt})
          }
    
          if(window.innerWidth<anchoTamMax)
          { if(window.innerWidth>=980 && window.innerWidth<1663)
            { if(window.innerWidth>=1400 && window.innerWidth<1663)
              { setTamWidth({...tamWidth,anchura:auxAnch})
              }
              else
              { setTamWidth({...tamWidth,anchura:auxAnch})
              }
            }
            else
            { setTamWidth({...tamWidth,anchura:auxAnch})
            }      
          }
          else
          { setTamWidth({...tamWidth,anchura:"100vw"})
          }
      }
  return (
    <div style={{justifyContent:"center",display:"flex",alignItems:"center",height:"100vh"}}>
    <div style={{display:"flex",
        width: "50vw",
        height:"50vh",// tamWidth.altura,
        backgroundImage: `url(${fondo})`,        
        "background-size": "100% 100%",
      }}/>
      </div>
  )
}

export default VentanaAuxiliar