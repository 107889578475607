import React from 'react'
//import {AuthContext} from '../context/AuthContext'
import {makeStyles,CssBaseline} from '@material-ui/core'
//import { grey } from '@material-ui/core/colors'
//import Cajon from './Cajon2'
//import {authUser} from "../funciones/AuthUser";
//import Navbar from './Navbar'
//import {useLocation,useHistory} from "react-router-dom";
//import {GeneralesContext} from '../context/GeneralesContext'
const estilos = makeStyles(theme=>({
    miroot:{
        display:'flex'
    },
    eltoolbar:theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        //backgroundColor: "#7F94C6",
        //padding: theme.spacing(1),
      },

}))
const Contenedor = (propiedades) => {
    const misclases = estilos()    
    //const [abrir,setAbrir]=React.useState(false);
    //const {usuario}=React.useContext(AuthContext)
    //const {usuario,guardarUsuario}=React.useContext(AuthContext)    
    //const {guardarGenerales}=React.useContext(GeneralesContext);  
    //const autenticado = authUser();
  /*   const funcionAbrir=()=>
    {
        setAbrir(!abrir);
    } */
    
    return (
        <div className={misclases.miroot}>
            <CssBaseline />
            {/* usuario.length !== 0  ?
                <Navbar funcionAbrir={funcionAbrir} usuario={usuario}
                colorTema={propiedades.colorTema} setColorTema={propiedades.setColorTema}
                />
                : null
             */}
            {/* usuario.length !== 0 && abrir ? ( 
                
                <span>
                   
                     
                    <Hidden >
                        <Cajon variant="permanent" usuario={usuario}
                            open={abrir}  funcionAbrir={funcionAbrir} 
                        />    
                    </Hidden>
                </span>
            ):null */}
            <div className={misclases.content}>
               
                {
                    propiedades.children
                }
                
            </div>
        </div>
    )
}

export default Contenedor
