import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {Box, TextField,FormControl, Tooltip,LinearProgress,
        Select,MenuItem,InputLabel, Card,Typography,Hidden,
        FormLabel,RadioGroup,FormControlLabel,Radio,
    } from '@material-ui/core' 
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import esLocale from "date-fns/locale/es";
import {DatePicker,MuiPickersUtilsProvider} from '@material-ui/pickers';
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from 'axios';
import qs from 'qs';
import Modal from '../generales/ModalStepperBack';
import moment from 'moment'
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify'; 
import Alert from '@material-ui/lab/Alert';

const ModalPerfilStepper = ({modalAbierto,setModalAbierto ,titulo,listMun,abrirModalNvoPerfilImg,listPlantilla,setNvoRegisPerfil}) => {    
    const source = axios.CancelToken.source(); 
    let fechaMax = moment(new Date()).subtract(18,'years')        
    let expCadenaCorreo = new RegExp("^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+[.]+[a-zA-Z]");
    const[nvoCliente,setNvoCliente]=React.useState({Nombre:'',Apellidos:'', FNacimien:fechaMax, Email:'' ,calle:'',
                                                Tel:'',ine:"",Municipio:"",Seccion:"",plantilla:0,redSocial:''
                                            });  
    const [activeStep, setActiveStep] = React.useState(0);    
    const [cargandoNom, setCargandoNom] = React.useState(false);    
    const [cargaPefil, setCargaPerfil] = React.useState(false);    
    const[auxSeccion,setAuxSeccion]=React.useState(null)
    const [espera,setEspera]=React.useState(false);
    const [mensaje, setMensaje] = React.useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = React.useState("success");
    const [btnDeshabilitado,setBtnDeshabilitado]= React.useState(false)
    const [aviso, setAviso] = React.useState(false);
    const[auxListSecc,setAuxListSecc]= React.useState([]);
    const{  Nombre,Apellidos,FNacimien,Email,calle,Tel,ine,Municipio,Seccion,plantilla,redSocial}=nvoCliente
    const[posicion,setPosicion]=React.useState([])
    const[mensajePos,setMensajePos]=React.useState("")
    const[cambioEfectivo,serCambioEfectivo] =React.useState(false)    
    const[nvoRegistro, setNvoRegistro] =React.useState([])    
    const[verCerrar, setVerCerrar] =React.useState(false)    
    
    const[arrError,setArrError] =React.useState([false,false,false,false,false,false])       
    const mostraMensaje=[true,true,true,true,true,false]

    React.useEffect(()=>{        
        setNvoCliente({...nvoCliente,plantilla:  listPlantilla[0].Id
    })
        setNvoRegisPerfil([])
        //getLocation()
    },[])

    const guardarFNaci = auxfecha =>
    {   
        setNvoCliente({
            ...nvoCliente,
            FNacimien : auxfecha  
        })
    };
    function getLocation() {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(showPosition);
        } 
        else 
        {  setMensajePos( "La Geolocalizacion no es soportada por tu navegador.");
            setPosicion([])
        }
      }
      
    function showPosition(position) {
        let Latitude= position.coords.latitude ;
        let Longitude= position.coords.longitude;        
        setPosicion({Lat:Latitude,Lon:Longitude})
    }

    const RelojFNacimiento = ()=>
    { return <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>            
         
          <Box  ml={1}   component="span" >           
            <DatePicker style={{width:100+'%'}} required
              disableFuture  format="dd/MM/yyyy"
              views={["year", "month", "date"]}
              label="Fecha Nacimiento" size="small"
              openTo="year" cancelLabel={"Cancelar"}                  
              okLabel="Seleccionar"    inputVariant="outlined"
              minDate="1900-03-14" maxDate={fechaMax}
              value={FNacimien} onChange={guardarFNaci}            
            />                          
          </Box>    
                                                
        </MuiPickersUtilsProvider>
    }

    const onChangeNom = e =>
	{   let expNopermitida = new RegExp('[0-9$.@#_&-+()/:;!?,<>=%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');             
        let expCadena = new RegExp('[A-Za-z]');  
        revisaExp(expNopermitida,expMenos,expMas,expCadena,e,2)        
	}; 

    function revisaExp(expNopermit,expMenos,expMas,expCadena,e,numero){
        let valor=e.target.value
        let nomb=e.target.name
        //console.log("agregandos",valor);
        if ( ( expCadena.test(valor) && !expNopermit.test(valor) 
        && !expMenos.test(valor) && !expMas.test(valor) ) 
        || valor===' '|| valor==='') 
        {
            setNvoCliente({
                ...nvoCliente, //copiamos el nvocliente
                [nomb] : valor//.toUpperCase()  
            })  
            if (arrError[numero]) {
                let auxArr=arrError
                auxArr[numero]=false                    
                setArrError(auxArr)         
            }
            
        }
    }

    const onChangeNumTel = e =>
	{   let expNopermitida= new RegExp('[A-Za-z$@#_&-+()/:;!?,<>=]');          
        let expMenos = new RegExp("'");
        let expMas = new RegExp('-');     
        let expCadena = new RegExp('[.0-9$]');    
        if (e.target.value.length<=10 ) {
            revisaExp(expNopermitida,expMenos,expMas,expCadena,e,4)    
        }        
	}; 
    
    async function llamadaNvoPerfil( auxFechaNacimient)  {       
        setEspera(true);
        let auxU=process.env.REACT_APP_ENLACE_PAG+`perfil-nuevo-2-1`;                                       
        let Dispvo =localStorage.getItem('Dispvo') ;    
        let auxDispV =Dispvo.length >= 12 ? Dispvo:'45vf2d1vdf21d2f'                                   
        let dataInfo = qs.stringify({                                                                                           
            'telefono':Tel,
            'nombre':Nombre.trim() +" "+Apellidos.trim(),
            'latitud':posicion.length > 0 ? posicion.Lat : 0,
            'longitud':posicion.length > 0 ? posicion.Lon : 0,
            'direccion':calle,
            'email':Email.length!==0 ? Email: "@",
            'version':'WEBSYSTEM',
            'plantilla':plantilla,
            'origen':process.env.REACT_APP_ORIGEN,
            'nacimiento':auxFechaNacimient,
            'ine':ine,
            'social':redSocial,
            'municipio':Municipio.Id,
            'seccion':auxSeccion.Id,
            'idDispositivo':auxDispV
            });
        let config = {
            url:auxU ,
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            data: dataInfo,
            cancelToken: source.token
        };
        let aux ={};           
        await axios(config)
        .then(function (response) {              
            aux=  response.data;                                          
           // console.log(aux)
            if (aux.length!==0) 
            {           
                setBtnDeshabilitado(true)     
                setVerCerrar(true)                                        
                let mensaje="Se registro correctamente el Perfil"
                setTipoAdvertencia("success")      
                setMensaje(mensaje);                
                setAviso(false);
                toast.success(mensaje,{
                    pauseOnFocusLoss: false,                    
                    toastId: `nvo-perfil${1}`
                })
                setNvoRegisPerfil({Nom:Nombre.trim() +" "+Apellidos.trim(),llave:aux.mensaje})
                setModalAbierto(false);
                setNvoRegistro(aux)
                serCambioEfectivo(true) 
                abrirModalNvoPerfilImg()
            }                                            
        })
        .catch(function (error) {                  
            console.log(`Error: ${error}`);              
            if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
            {
                console.log('datos incorrectos' );  
            }
        });             
        setEspera(false);         
    };

    const onChangeCorreo = e =>
	{   let expNopermitida = new RegExp('[%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');             
        let expCadena = new RegExp('[A-Za-z0-9$@#_&-+()/.;!?,<>=]');  
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && !expMenos.test(e.target.value) &&
            !expMas.test(e.target.value) ) || e.target.value===' '|| e.target.value==='') 
        { 
            setNvoCliente({
                ...nvoCliente, //copiamos el nvocliente
                [e.target.name] : e.target.value  
            })
            if (aviso) 
            {   setMensaje('');
                setAviso(false);
            }
            if (expCadenaCorreo.test(e.target.value )) 
            {   //console.log(expCadenaCorreo,e.target.value);
                //setBtnDeshabilitado(false)
                let auxArr=arrError
                auxArr[5]=false                    
                setArrError(auxArr) 
            } 
            else {
                //setBtnDeshabilitado(true)
            }
        }		
	};    

    const mandaAlerta=(mensaje,tipoAdvert)=>
    {
        setMensaje(mensaje);
        setTipoAdvertencia(tipoAdvert)  
        setAviso(true);      
    }
      
    function validaVariable(auxCampo,auxMensaje,auxTamanio) 
    {   let respuesta=false
        if ( !verificaNulos2(auxCampo,auxMensaje) ) 
        {   if (auxCampo.length>=auxTamanio ) 
            {                
                respuesta=true                    
            }else
            {  let mensaje= 'Campo '+auxMensaje +' incompleto, debe escribir un '+ auxMensaje+' valido'         
                mandaAlerta(mensaje,"warning") 
            }
        }        
        return respuesta;
    } 
    function validaVarDinamico(auxCampo,auxMensaje,auxTamanio,auxError) 
    {   let respuesta=false
        if ( !verificaNulos2(auxCampo,auxMensaje) ) 
        {   if (auxCampo.length>=auxTamanio ) 
            {                
                respuesta=true                    
            }else
            {  let mensaje= auxError
                mandaAlerta(mensaje,"warning") 
            }
        }        
        return respuesta;
    }
    async function llamadaApi(data, url, miFuncion) {
        let config = {
          method: "post",
          url: process.env.REACT_APP_ENLACE_PAG + url,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: data,
          cancelToken: source.token,
        };
        await axios(config)
          .then(function (response) {
            let auxiliar = response.data;
                         
            //console.log(auxiliar);
            miFuncion(auxiliar);
            
          })
          .catch(function (error) {
            console.log(error);
            if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.mensaje === "Datos Incorrectos"
            ) {
              console.log("datos incorrectos");
            }
          });
    }

    async function llamadaApiCarga(data, url, miFuncion,setCarga) {
        setCarga(true)
        let config = {
          method: "post",
          url: process.env.REACT_APP_ENLACE_PAG + url,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: data,
          cancelToken: source.token,
        };
        await axios(config)
          .then(function (response) {
            let auxiliar = response.data;
            miFuncion(auxiliar);            
          })
          .catch(function (error) {
            console.log(error);
            if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.mensaje === "Datos Incorrectos"
            ) {
              console.log("datos incorrectos");
            }
          });
          setCarga(false)
    }

    const veriCorreo = (correo) => {
        let respuesta = false;
        let expCadena = expCadenaCorreo//new RegExp("^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$");
        if (correo.trim() === "" || correo.trim() === null) {
          // respuesta=true;
          let mensaje = "Email vacio, escriba un Email ";
          mandaAlerta(mensaje, "warning");
        } else {
          if (expCadena.test(correo)) {
            respuesta = true;
          } else {
            let mensaje = "Debe escribir un Email valido ";
            mandaAlerta(mensaje, "warning");
          }
        }
        return respuesta;
      };

    const verificaSelect = (campo,descripcion)=>{
        let respuesta =false;              
        if( (typeof campo !== 'number' && typeof campo === 'string' 
            && (campo.trim() === '' || campo.trim() === null) || campo===null )
        ) 
        {   respuesta=true;                
            let mensaje='Debe seleccionar '+descripcion;               
            mandaAlerta(mensaje, "warning")
        }
        return(respuesta);
    }

    function guardarDatos(e)
    {   e.preventDefault()                
         setBtnDeshabilitado(true)        
        setMensaje('');                
        setAviso(false); 
        let auxFechaNacimient=moment( FNacimien).format('YYYY-MM-DD')        
        llamadaNvoPerfil(auxFechaNacimient+'T00:00:00')                
        //VerificaCorreo()                                  
                                     
    } 
    
    const verificaNulos2 = (campo,descripcion)=>{
        let respuesta =false;              
        if( (typeof campo !== 'number' && typeof campo === 'string' 
            && (campo.trim() === '' || campo.trim() === null) )
        ) 
        {   respuesta=true;                
            let mensaje='Debe escribir su '+descripcion;                
            mandaAlerta(mensaje, "warning")
        }
        return(respuesta);
    }

    const llenaListSecc = (muniSelect) => {
        let Dispvo = localStorage.getItem("Dispvo");
        let data = qs.stringify({
          municipio: muniSelect,
          idDispositivo: Dispvo,
        });
        let url = "lugar-seccion-list";
        function agregaSecc(auxiliar){
            if (auxiliar.length !== 0) {
                //console.log(auxiliar);
                let nuevoArr=[]
                auxiliar.forEach(element => {
                    nuevoArr.push({Id:element.Id,num:""+element.Numero})
                });
                // console.log(auxiliar);
                //console.log(listMun);
                //console.log(auxiliar[0].Numero.toString());
                setAuxListSecc(auxiliar);
            } 
        }
        llamadaApi(data, url, agregaSecc);
    };

    const VerificaCorreo = () => {
        let Dispvo = localStorage.getItem("Dispvo");
        let data = qs.stringify({
            cadena:Email,
          idDispositivo: Dispvo,
        });
        let url = "perfil-verifica";
        llamadaApi(data, url, respuestaCorreo);
    };

    const VerificaPerfl = (fecha) => {
        let Dispvo = localStorage.getItem("Dispvo");
        let data = qs.stringify({
            nombre:Nombre.trim() +" "+Apellidos.trim(),
            nacimiento:fecha+'T00:00:00',
            municipio:Municipio.Id,
            idDispositivo: Dispvo,
        });
        let url = "perfil-verifica-duplicado";
        llamadaApiCarga(data, url, respuestaPerfil,setCargaPerfil);
    };

    const VerificaNombre = () => {
        let Dispvo = localStorage.getItem("Dispvo");
        let data = qs.stringify({
            cadena:Nombre.trim() +" "+Apellidos.trim(),
            idDispositivo: Dispvo,
        });
        let url = "perfil-verifica-cadena";
        llamadaApiCarga(data, url, respuestaNombre,setCargandoNom);
    };
    
    function respuestaNombre(auxiliar){        
        const isEmpty = Object.keys(auxiliar).length === 0                
        let auxArr=arrError
        if (isEmpty || auxiliar.coincidencia===0 ||  auxiliar.coincidencia==="0"   ) 
        {      
            limpiarAlerta()
            auxArr[2]=false  
            let auxFechaNacimient=moment( FNacimien).format('YYYY-MM-DD')
            VerificaPerfl(auxFechaNacimient)
        } 
        else 
        {   let mensaje = "Nombre invalido, ingrese un Nombre diferente.";
            toast.warning(mensaje,{
                pauseOnFocusLoss: false,                    
                toastId: `nvo-perfil${5}`
            })  
            handleBack()          
            mandaAlerta(mensaje, "warning");
            auxArr=arrError
            auxArr[2]=true                    
            setArrError(auxArr) 
            setBtnDeshabilitado(false)
        }
    }

    function respuestaCorreo(auxiliar){
        //console.log(auxiliar);
        const isEmpty = Object.keys(auxiliar).length === 0
        if (isEmpty || auxiliar.mensaje===0 ||  auxiliar.mensaje==="0"   ) 
        {   let auxFechaNacimient=moment( FNacimien).format('YYYY-MM-DD')
            llamadaNvoPerfil(auxFechaNacimient+'T00:00:00') 
            //setBtnDeshabilitado(false)
            //console.log("no esta registrado el correo ");
        } 
        else 
        {   let mensaje = "Email ya registrado, ingrese un Email diferente.";
            toast.warning(mensaje,{
                pauseOnFocusLoss: false,                    
                toastId: `nvo-perfil${4}`
            })            
            mandaAlerta(mensaje, "warning");
            let auxArr=arrError
            auxArr[5]=true                    
            setArrError(auxArr) 
            setBtnDeshabilitado(false)
        }
    }

    function respuestaPerfil(auxiliar){        
        const isEmpty = Object.keys(auxiliar).length === 0
        let auxArr=arrError
        if (isEmpty || auxiliar.rechazo===0 ||  auxiliar.rechazo==="0"   ) 
        {   //
            limpiarAlerta()
            auxArr[2]=false  
        } 
        else 
        {   //let mensaje = "Nombre ya registrado, ingrese un Nombre diferente.";
            let mensaje = auxiliar.mensaje//"Nombre ya registrado, ingrese un Nombre diferente.";
            toast.warning(mensaje,{
                pauseOnFocusLoss: false,                    
                toastId: `nvo-perfil${6}`
            })            
            mandaAlerta(mensaje, "warning");
            handleBack() 
            mandaAlerta(mensaje, "warning");
            auxArr=arrError
            auxArr[2]=true                    
            setArrError(auxArr) 
            //setBtnDeshabilitado(false)
        }
    }

    const guardarMuni=(e)=>{    
        if (e!== null && e!== undefined) 
        {   let auxArr=arrError
            auxArr[0]=false                    
            setArrError(auxArr) 
            llenaListSecc(e.Id);
            setNvoCliente({
                ...nvoCliente,
                Municipio : e  ,  
                Seccion :""  
            })      
            setMensaje('');                
            setAviso(false); 
        }else
        {   setNvoCliente({
                ...nvoCliente,
                Municipio : "" ,Seccion :""   
            })           
            setAuxListSecc([])              
        }    
        setAuxSeccion(null)      
    }

    const alertaGeneral=(mensaje)=>{
      return  <Box mb={2}  ><Alert severity="info" >{mensaje}</Alert></Box>
    }

    const guardarSeccionSelect=(e)=>{   
        //console.log(e); 
        if (e!== null && e!== undefined) 
        { //setMuniSelect(e)     
            let auxArr=arrError
            auxArr[1]=false                    
            setArrError(auxArr)   
            setNvoCliente({
                ...nvoCliente,
                Seccion :e//e.target.value 
            })      
            setMensaje('');                
            setAviso(false); 
        }else
        { setNvoCliente({
            ...nvoCliente,
            Seccion : ""  
        })           
        }        
    }  

    const guardarSeccionSelect2=(e)=>{   
        //console.log(e); 
        if (e!== null && e!== undefined) 
        { //setMuniSelect(e)     
            let auxArr=arrError
            auxArr[1]=false                    
            setArrError(auxArr)   
            setAuxSeccion(e)
            setMensaje('');                
            setAviso(false); 
        }else
        {   setAuxSeccion(null)      
        }        
    }  

    const DatosGenerales=(
        <div>            
           <Box   mt={2} mb={2} display="flex" flexDirection={"row"}>
                {listMun.length!==0 ?
                <Autocomplete
                    id="muni-select-nvoPerfil" size='small' style={{ width: 20.7+'rem' }}
                    onChange={(e,option)=>guardarMuni(option)}  value={Municipio}      
                    getOptionLabel={(option) => option.Nom} name="Municipio"             
                    noOptionsText={"Sin coincidencias"} options={listMun}
                    renderInput={(params) => (
                        <TextField {...params} size='small' 
                        label="Municipio" variant="outlined" />
                    )} 
                />
                :<LinearProgress/>}
                {auxListSecc.length!==0 ?                
                <Box display={"flex"} mb={1}>
                <FormControl size='small'  style={{marginLeft:1.6+'rem', minWidth:6.5+'rem'}} >
                    <InputLabel size='small' variant="outlined" id="Seccion-label-nvo">Sección</InputLabel>
                    <Select  size='small' variant="outlined"
                        label="Sección" id="Seccion-select2" name="Seccion"
                        value={Seccion} onChange={guardarSeccionSelect}
                    >
                        {auxListSecc.map((secc, index) => {
                            if(secc.Id>0) {
                            return(
                            <MenuItem size='small' value={secc.Id} key={secc.Id}>
                                <em>{secc.Numero }</em>
                            </MenuItem>
                            )}
                        } )}                    
                    </Select>
                    </FormControl>
                    </Box>
                :   null
                }
            </Box>               
          
            <TextField required id="Nombre-input" onChange={onChangeNom} style={{marginBottom:.8+'rem',width:22+'rem'}}
                label="Nombre" value={Nombre} name='Nombre'size="small" autoComplete='off' // defaultValue="Nombre"                
                variant="outlined"
            />
            {RelojFNacimiento()}
            <br/>           
            <TextField  id="CInt-input" onChange={onChangeNumTel} style={{marginBottom:1+'rem',marginRight:1+'rem',width:9.2+'rem'}}
                label="Teléfono" defaultValue="Tel" value={Tel} name='Tel' size="small" type="number"
                inputProps= {{ maxlength: 10 }} autoComplete='off' variant="outlined"
            /> 
            <TextField  id="Email-input" onChange={onChangeCorreo} style={{width:18+'rem'}}
                label="Email"  value={Email} name='Email' size="small" autoComplete='off'
                variant="outlined"
            />  
            <br/>
            
        </div> 
    )     
      
    const Paso1=(
        <div>            
           <Box    display="flex" flexDirection={"column"}>
               {!aviso &&  alertaGeneral("Seleccione su Municipio.")}
                {listMun.length!==0 ?
                <Autocomplete 
                    id="muni-select-nvoPerfil" size='small' style={{ width: 100+'%' }}
                    onChange={(e,option)=>guardarMuni(option)}  value={Municipio}      
                    getOptionLabel={(option) => option.Nom} name="Municipio"             
                    noOptionsText={"Sin coincidencias"} options={listMun}
                    renderInput={(params) => (
                        <TextField {...params} size='small' required error={arrError[0]}
                        label="Municipio" variant="outlined" />
                    )} 
                    
                />
                :<LinearProgress/>}
               
            </Box>                         
        </div> 
    )     

    const PasoSecc=(
        <div>            
            <Hidden smUp>
           <Box display="flex" flexDirection={"column"} >       
            { !aviso  &&  alertaGeneral("Seleccione la Sección.")}   
                {auxListSecc.length>0 ?                
                <Box display={"flex"}   justifyContent={"center"} >                
                <Autocomplete  options={auxListSecc}
                    id="secc-select-nvo" size='small' style={{ width: 75+'%' }} 
                    onChange={(e,option)=> guardarSeccionSelect2(option)}  value={auxSeccion}     
                    getOptionLabel={(option) => option.Numero.toString()} name="Seccion"  
                    //getOptionSelected={(option, value) => (  ayuda(value)) }              
                    noOptionsText={"Sin coincidencias"}
                    renderInput={(params) => (
                        <TextField {...params} size='small' required error={arrError[1]}
                        label="Sección" variant="outlined" />
                    )} 
                    
                />              
                </Box>                                              
                :   null
                }
            </Box>                                      
            </Hidden>
            <Hidden xsDown>
           <Box display="flex"  flexDirection={"column"} justifyContent={"center"}>       
            { !aviso  &&  alertaGeneral("Seleccione la Sección.")}   
                {auxListSecc.length>0 ?                
                <Box display={"flex"}   justifyContent={"center"} >
                <Autocomplete  options={auxListSecc}
                    id="secc-select-nvo2" size='small' style={{ width: 60+'%' }} 
                    onChange={(e,option)=> guardarSeccionSelect2(option)}  value={auxSeccion}   
                   // getOptionSelected={(option, value) => option.Numero === value.Numero }   
                    getOptionLabel={(option) =>" "+ option.Numero} name="Seccion"                           
                    noOptionsText={"Sin coincidencias"}
                    renderInput={(params) => (
                        <TextField {...params} size='small' required error={arrError[1]}
                        label="Sección" variant="outlined" />
                    )} 
                    
                />              
                    </Box>
                :   <LinearProgress/>
                }
            </Box>                                      
            </Hidden>
        </div> 
    )    
    const Paso3=(
        <div>       
            {!aviso  &&  alertaGeneral("Escriba su Nombre.")}    
            <Hidden smUp>
            <Box display={"flex"} flexDirection="column">
            <Box >
                           
            <TextField required id="Nombre-input2" onChange={onChangeNom} style={{ width:100+'%'}}
                label="Nombre" value={Nombre} name='Nombre'size="small" autoComplete='off' // defaultValue="Nombre"                
                variant="outlined" error={arrError[2]}
            />
                </Box>                          
            <Box mt={2} >
            <TextField required id="Apellidos-input2" onChange={onChangeNom} style={{ width:100+'%'}}
                label="Apellidos" value={Apellidos} name='Apellidos'size="small" autoComplete='off' // defaultValue="Nombre"                
                variant="outlined" error={arrError[2]}
            />
            </Box>       
            </Box>  
            </Hidden>
            <Hidden xsDown>
            <Box display={"flex"}>
            <Box flexGrow={2}>
                           
            <TextField required id="Nombre-input2" onChange={onChangeNom} style={{ width:100+'%'}}
                label="Nombre" value={Nombre} name='Nombre'size="small" autoComplete='off' // defaultValue="Nombre"                
                variant="outlined" error={arrError[2]}
            />
                </Box>
                <Box flexGrow={2}></Box>              
            <Box flexGrow={5}>
            <TextField required id="Apellidos-input2" onChange={onChangeNom} style={{ width:100+'%'}}
                label="Apellidos" value={Apellidos} name='Apellidos'size="small" autoComplete='off' // defaultValue="Nombre"                
                variant="outlined" error={arrError[2]}
            />
            </Box>       
            </Box>  
            </Hidden>                             
        </div> 
    )     

    const Paso4=(
        <Box display="flex" justifyContent={"center"} flexDirection={"column"}> 
            {!aviso  && alertaGeneral("Seleccione su Fecha de Nacimiento.")}                                    
            {RelojFNacimiento()}                         
        </Box>            
    )     

    const Paso5=(
        !cargandoNom && !cargaPefil ?<Box display="flex"  flexDirection={"column"}>
            {!aviso  && alertaGeneral("Escriba su Teléfono.")}  
            <Hidden smUp>
                 <Box display="flex" justifyContent={"center"}  >
                <TextField  id="Tel-input1" onChange={onChangeNumTel} style={{ width:75+'%'}}
                    label="Teléfono" defaultValue="Tel" value={Tel} name='Tel' size="small" type="number"
                    inputProps= {{ maxlength: 10 }} autoComplete='off' variant="outlined"
                    required error={arrError[4]}
                />                        
                </Box>
            </Hidden>              
            <Hidden xsDown>
                 <Box display="flex" justifyContent={"center"}  >
                <TextField  id="Tel-input2" onChange={onChangeNumTel} style={{ width:60+'%'}}
                    label="Teléfono" defaultValue="Tel" value={Tel} name='Tel' size="small" type="number"
                    inputProps= {{ maxlength: 10 }} autoComplete='off' variant="outlined"
                    required error={arrError[4]}
                />                        
                </Box>
            </Hidden>  
        </Box>
        :<Box><LinearProgress/></Box>
    ) 

    const Paso7=(
        <Box display="flex" justifyContent={"center"} flexDirection={"column"} mb={2}> 
            {/* !aviso  && alertaGeneral("Escriba su Facebook, Instagram o Twitter.") */}                                                     
            <TextField  id="redSocial-input" onChange={onChangeCorreo} style={{width:100+'%'}}
                label="Red Social"  value={redSocial} name='redSocial' size="small" autoComplete='off'
                variant="outlined" required error={arrError[5]}
            />               
        </Box>
    )   

    const Paso6=(
        <Box display="flex" justifyContent={"center"} flexDirection={"column"}> 
            {!aviso  && alertaGeneral("Escriba su Email, Facebook, Instagram o Twitter.")}                                                     
            {Paso7} 
            <TextField  id="Email-input" onChange={onChangeCorreo} style={{width:100+'%'}}
                label="Email"  value={Email} name='Email' size="small" autoComplete='off'
                variant="outlined" required error={arrError[5]}
            />  
                        
        </Box>
    )

      
   
    const cerrarBorrar = () => {    
        //setOpen(false);        
        setAviso(false);
        setModalAbierto(false);
        if (cambioEfectivo) 
        {   //setActualizar(!actualizar)  
           // setNvoRegisPerfil({Nom:Nombre,llave:203})
           setNvoRegisPerfil({Nom:Nombre,llave:nvoRegistro.mensaje})                                   
        }
    };

    function contenidoStep(step) {
        switch (step) {
            case 0:
                return Paso1;// Paso1;
            case 1:
                return PasoSecc;
            case 2:// cambie nombre por nacimiento
                return Paso4;         
            case 3:// cambie nacimiento por nombre
                return Paso3;
            case 4:
                return Paso5;
            case 5:
                return Paso6;         
            /* case 6:
                return Paso7;      */    
            default:
                return 'Paso sin contenido';
        }
    }

    function limpiarAlerta(){
        setMensaje(' ');
        setTipoAdvertencia("warning")  
        setAviso(false);
    }

    function validaParte1(num){
        let respuesta =false      
        let auxArr=arrError          
        if (!verificaSelect(Municipio,"un Municipio")) 
        {
            respuesta=true    
            limpiarAlerta()                           
            auxArr[num]=false
        }     
        else{            
            auxArr[num]=true            
        }   
        setArrError(auxArr)          
        return respuesta
    }

    function validaParte2(num){
        let respuesta =false    
        let auxArr=arrError       
        if ( !verificaSelect(auxSeccion,"su Sección")) 
        {
            respuesta=true   
            limpiarAlerta() 
            auxArr[num]=false            
        }else{
            auxArr[num]=true 
        } 
        setArrError(auxArr)  
        return respuesta
    }
   
    function validaParte3(num){
        let respuesta =false    
        let auxArr=arrError         
        if ( validaVariable(Nombre,'Nombre',3) && validaVariable(Apellidos,'Apellido',3) ) 
        {   //VerificaNombre()
            respuesta=true   
            limpiarAlerta()
            auxArr[num]=false             
        }else{
            auxArr[num]=true 
        }   
        setArrError(auxArr) 
        return respuesta
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    function validaParte32(num){
        let respuesta =false    
        let auxArr=arrError         
        if ( validaVariable(Nombre,'Nombre',3) && validaVariable(Apellidos,'Apellido',3) ) 
        {   VerificaNombre(num)   
            respuesta=true   
            limpiarAlerta()
            auxArr[num]=false                                                                        
        }
        else
        {   auxArr[num]=true 
        }   
        setArrError(auxArr) 
        return respuesta
    }

    function validaParte4(num){
        let respuesta =false 
        let auxArr=arrError           
        let auxMenError="Teléfono invalido, mínimo 10 digitos"
        if (validaVarDinamico(Tel,'Teléfono',10,auxMenError)) 
        {
            respuesta=true   
            limpiarAlerta()  
            auxArr[num]=false           
        }else{
            auxArr[num]=true 
        }   
        setArrError(auxArr)
        return respuesta
    }
    function validaParte5(num){
        let respuesta =false  
        let auxArr=arrError        
        if ( veriCorreo(Email)) 
        {
            respuesta=true   
            limpiarAlerta() 
            auxArr[num]=false            
        }else{
            auxArr[num]=true 
        }   
        setArrError(auxArr) 
        return respuesta
    }

    function validaPasos(e)
    {   let respuesta=false
       // console.log("validando  modal");        
        switch (e) {
            case 0:
              respuesta=validaParte1(0)
              break
            case 1:
               respuesta=validaParte2(1)            
               break
            case 2://cambie nombre por nacimiento
               respuesta=true            
               break
            case 3://cambie nacimiento por nombre  
               respuesta=validaParte32(2)//validaParte2()            
               break
            case 4:
               respuesta=validaParte4(4)            
               break
            case 5:
                respuesta=true//validaParte5(5)            
                break  
            /* case 6:
                respuesta=true// redes sociales            
                break   */                 
            default:               
               break
        }
        return respuesta                                          
    } 

    function getSteps() {
        return ['', '', '','', '', ''];
    }
    return (
    <>           
    <Modal contenidoStep={contenidoStep} getSteps={getSteps}
        titulo={titulo} modalAbierto={modalAbierto}
        setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
        mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
        aviso={aviso} btnDeshabilitado={btnDeshabilitado}
        setAviso={setAviso} tamanio={'md'} cargando={espera}     
        cerrarBorrar={cerrarBorrar}    validaPasos={validaPasos}
        btnVerCerrar={verCerrar} mostraMensaje={mostraMensaje}
        activeStep={activeStep} setActiveStep={setActiveStep}
        cargandoNom={cargandoNom} cargaPefil={cargaPefil}
    >
        {DatosGenerales}
    </Modal>         
    </>
    )
}

export default ModalPerfilStepper