import React from "react";
import Modal from '../generales/ModalStepperBack';
import {
  Box, FormControlLabel, Radio, RadioGroup, FormLabel,
  TextField,
  FormControl,
  LinearProgress,
  Hidden,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { toast } from 'react-toastify';
import axios from 'axios';
import qs from 'qs';
import 'react-toastify/dist/ReactToastify.css';
import Alert from '@material-ui/lab/Alert';

const SugerenciaModal = ({
  modalAbierto,
  setModalAbierto,
  titulo,
  listMun
}) => {
  const [activeStep, setActiveStep] = React.useState(0);  
  const [mensaje, setMensaje] = React.useState("");
  const [tipoAdvertencia, setTipoAdvertencia] = React.useState("");
  const [aviso, setAviso] = React.useState(false);
  const [btnDeshabilitado, setBtnDeshabilitado] = React.useState(true);
  const [espera, setEspera] = React.useState(false);
  const[verCerrar, setVerCerrar] =React.useState(false)    
  const[arrError,setArrError] =React.useState([false,false,false,false,false,false])   
  let expCadenaCorreo = new RegExp("^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+[.]+[a-zA-Z]");
  const mostraMensaje=[true,true,true,true,false,true]
  const [cargandoNom, setCargandoNom] = React.useState(false);  
  const source = axios.CancelToken.source();
  /*  React.useEffect(() => {
     toast.success("probando",{
       pauseOnFocusLoss: false,                    
       toastId: `nva-sug${1}`
     })  
   }, [ ]) */

  const [nvaSugerencia, setNvaSugerencia] = React.useState({
    email: "",
    tel: "",
    sugerencia: "",
    nombre: '',
    genero: '',
    Municipio: '',
    pregunta1:'',
    pregunta2:'',
    pregunta3:'',
  });

  const { email, tel, sugerencia, nombre, genero, Municipio ,pregunta1,pregunta2,pregunta3 } = nvaSugerencia; 

  function validaVariable(auxCampo, auxMensaje, auxTamanio) {
    let respuesta = false
    if (!verificaNulos2(auxCampo, auxMensaje)) {
      if (auxCampo.length >= auxTamanio) {
        respuesta = true
      } else {
        let mensaje = auxMensaje + ' incompleto, debe escribir un ' + auxMensaje + ' valido'
        mandaAlerta(mensaje, "warning")
      }
    }
    return respuesta;
  }

  const verificaNulos2 = (campo, descripcion) => {
    let respuesta = false;
    if ((typeof campo !== 'number' && typeof campo === 'string'
      && (campo.trim() === '' || campo.trim() === null))
    ) {
      respuesta = true;
      let mensaje = 'Escriba su ' + descripcion;
      mandaAlerta(mensaje, "warning")
    }
    return (respuesta);
  }

  const verificaSuge = (campo) => {
    let respuesta = false;
    if (campo.length < 50 || (typeof campo !== 'number' && typeof campo === 'string'
      && (campo.trim() === '' || campo.trim() === null))
    ) {
      respuesta = true;
      let mensaje = 'Escriba su comentario o sugerencia (mínimo 50 caracteres)';
      mandaAlerta(mensaje, "warning")
    }
    console.log(campo.length);
    return (respuesta);
  }

  const verificaNulos = (campo, descripcion) => {
    let respuesta = false;
    if ((typeof campo !== 'number' && typeof campo === 'string'
      && (campo.trim() === '' || campo.trim() === null))
    ) {
      respuesta = true;
      let mensaje = 'No se lleno el campo ' + descripcion;
      mandaAlerta(mensaje, "warning")
    }
    return (respuesta);
  }
  const verificaSelect = (campo, descripcion) => {
    let respuesta = false;
    if ((typeof campo !== 'number' && typeof campo === 'string'
      && (campo.trim() === '' || campo.trim() === null))
    ) {
      respuesta = true;
      let mensaje = 'Seleccione ' + descripcion;
      mandaAlerta(mensaje, "warning")
    }
    return (respuesta);
  }
  const onChangeNom = e =>
	{   let expNopermitida = new RegExp('[0-9$.@#_&-+()/:;!?,<>=%{}*|`]');  
      let expMenos = new RegExp("'");
      let expMas = new RegExp('"');             
      let expCadena = new RegExp('[A-Za-z]');  
      revisaExp(expNopermitida,expMenos,expMas,expCadena,e,1)        
	}; 

  function revisaExp(expNopermit, expMenos, expMas, expCadena, e, numero) {
    let valor = e.target.value;
    let nomb = e.target.name;
    //console.log("agregandos",valor);
    if (
      (expCadena.test(valor) && !expNopermit.test(valor) &&
        !expMenos.test(valor) && !expMas.test(valor)) ||
        valor === " " || valor === ""
    ) 
    {
      setNvaSugerencia({
        ...nvaSugerencia, //copiamos el nvocliente
        [nomb]: valor, //.toUpperCase()
      });
      if (arrError[numero]) {
        let auxArr = arrError;
        auxArr[numero] = false;
        setArrError(auxArr);
      }
    }
  }
  function revisaExp2(expNopermit, expMenos, expMas, expCadena, e, numero) {
    let valor = e.target.value;
    let nomb = e.target.name;
    //console.log("agregandos",valor);
    if (
      (expCadena.test(valor) && !expNopermit.test(valor) &&
        !expMenos.test(valor) && !expMas.test(valor) && valor.indexOf(" ")=== -1) ||
        valor === "" 
    ) 
    {
      setNvaSugerencia({
        ...nvaSugerencia, //copiamos el nvocliente
        [nomb]: valor, //.toUpperCase()
      });
      if (arrError[numero]) {
        let auxArr = arrError;
        auxArr[numero] = false;
        setArrError(auxArr);
      }
    }
  }
  const onChangeNumTel = (e) => {
    let expNopermitida = new RegExp("[A-Za-z$@#_&-+()/:;!?,<>=]");
    let expMenos = new RegExp("'");
    let expMas = new RegExp("-");
    let expCadena = new RegExp("[.0-9$]");
    if (e.target.value.length<=10 ) {
      revisaExp2(expNopermitida,expMenos,expMas,expCadena,e,3)    
    }          
  };
  const onChangeSug = (e) => {
    let expNopermitida = new RegExp("[{}*|`]");
    let expMenos = new RegExp("'");
    let expMas = new RegExp('"');
    let expCadena = new RegExp("[A-Za-z0-9$@%#_&-+()/.;!?,<>=]");
    if (
      (expCadena.test(e.target.value) &&
        !expNopermitida.test(e.target.value) &&
        !expMenos.test(e.target.value) &&
        !expMas.test(e.target.value)) ||
      e.target.value === " " ||
      e.target.value === ""
    ) {
      setNvaSugerencia({
        ...nvaSugerencia, //copiamos el nvocliente
        [e.target.name]: e.target.value,
      });
      if (aviso) {
        setMensaje("");
        setAviso(false);
      }
      if (e.target.value.length>=50 &&e.target.name==="sugerencia" ) 
      {   //console.log(expCadenaCorreo,e.target.value);
        setBtnDeshabilitado(false)
        let auxArr=arrError
        auxArr[5]=false                    
        setArrError(auxArr) 
      } 
      else {
        if (e.target.value.length<50 &&e.target.name==="sugerencia" ) 
        {   setBtnDeshabilitado(true)
        }         
      }
    }
  };

  const onChangeCorreo = (e) => {
    //let expNopermitida = new RegExp('[#._:!%-({})/*=?|]');
    let expNopermitida = new RegExp("[%{}*|`]");
    let expMenos = new RegExp("'");
    let expMas = new RegExp('"');
    let expCadena = new RegExp("[A-Za-z0-9$@#_&-+()/.;!?,<>=]");
    if (
      (expCadena.test(e.target.value) &&
        !expNopermitida.test(e.target.value) &&
        !expMenos.test(e.target.value) &&
        !expMas.test(e.target.value)) ||
      e.target.value === " " ||
      e.target.value === ""
    ) {
      setNvaSugerencia({
        ...nvaSugerencia, //copiamos el nvocliente
        [e.target.name]: e.target.value,
      });
      if (aviso) {
        setMensaje("");
        setAviso(false);
      }
    }
  };

  async function llamadaApi(data, url, miFuncion) {
    setEspera(true);
    let config = {
      method: "post",
      url: process.env.REACT_APP_ENLACE_PAG + url,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
      cancelToken: source.token,
    };
    await axios(config)
      .then(function (response) {
        let auxiliar = response.data;
        //console.log(auxiliar);
        if (auxiliar.length !== 0) {
          //console.log("antes de cargar");
          miFuncion(auxiliar)
        }
      })
      .catch(function (error) {
        console.log(error);
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.mensaje === "Datos Incorrectos"
        ) {
          console.log("datos incorrectos");
        }
      });
    setEspera(false);
  }

  const respuestaNvaSug = async (respuesta) => {
    //console.log("antes de cargar");
    if (respuesta.respuesta) {
      let mensaje = "Se envio correctamente la sugerencia"
      toast.success(mensaje, {
        pauseOnFocusLoss: false,
        toastId: `nva-sug${1}`
      })
      setBtnDeshabilitado(true)
      setModalAbierto(false)
    }

  }

  async function llamadaNvaSug() {

    let url = `solicitud-nuevo-1-1`;
    let Dispvo = localStorage.getItem("Dispvo");
    let auxDispV = Dispvo.length >= 12 ? Dispvo : "45vf2d1vdf21d2f";
    let auxMun = Municipio.length !== 0 ? Municipio.Id : 0
    let dataInfo = qs.stringify({
      municipio: auxMun,
      seccion: 0,
      nombre: nombre,
      genero: genero.length !== 0 ? genero : ' ',
      telefono: tel,
      email:  email.length !==0 ? email :'@',
      mensaje: sugerencia,
      lan: 0.0,
      lon: 0.0,
      origen:process.env.REACT_APP_ORIGEN,
      pregunta1:pregunta1,
      pregunta2:pregunta2,
      pregunta3:pregunta3,
      idDispositivo: auxDispV,
    });
    llamadaApi(dataInfo, url, respuestaNvaSug);

  }

 
  const mandaAlerta = (mensaje, tipoAdvert) => {
    setMensaje(mensaje);
    setTipoAdvertencia(tipoAdvert);
    setAviso(true);
  };

  const guardarMuni = (e) => {
    if (e !== null && e !== undefined) {
      let auxArr=arrError
      auxArr[0]=false                    
      setArrError(auxArr) 
      setNvaSugerencia({
        ...nvaSugerencia,
        Municipio: e,
      })
      setMensaje('');
      setAviso(false);
    } else {
      setNvaSugerencia({
        ...nvaSugerencia,
        Municipio: ""
      })
    }
  }

  const handleChange = (event) => {
    setNvaSugerencia({
      ...nvaSugerencia,
      genero: event.target.value
    });
    console.log(event.target.value);
    let auxArr=arrError
    auxArr[2]=false                    
    setArrError(auxArr) 
  };
  const guardarDatos = () => {    
    if (!verificaSelect(Municipio, "un Municipio") && validaVariable(nombre, 'Nombre', 3) && !verificaSelect(genero, "el Genero")
      && validaVariable(tel, 'Telefono', 10) && !verificaSuge(sugerencia)) {
      setMensaje('');
      setAviso(false);     
      llamadaNvaSug()
    }
  };
  const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
      },
    },
  }));

  const classes = useStyles();
  const alertaGeneral=(mensaje)=>{
    return  <Box mb={2}  ><Alert severity="info" >{mensaje}</Alert></Box>
  }
  const Paso1 = (
    <Box display="flex" flexDirection={"column"}>
      {!aviso &&  alertaGeneral("Seleccione su Municipio.")}
      {listMun.length !== 0 ?
        <Autocomplete
          id="muni-select-nvoPerfil" size='small' style={{ width: 100 + '%' }}
          onChange={(e, option) => guardarMuni(option)} value={Municipio}
          getOptionLabel={(option) => option.Nom} name="Municipio"
          noOptionsText={"Sin coincidencias"} options={listMun}
          renderInput={(params) => (
            <TextField {...params} size='small' required error={arrError[0]}
              label="Municipio" variant="outlined" />
          )}
        />
        : <LinearProgress />}
    </Box>
  )

  const Paso2 = (
    <Box>
      {!aviso  &&  alertaGeneral("Escriba su Nombre.")}      
      <TextField
        id="nombre-input02" required
        onChange={onChangeNom}
        style={{ width: 100 + "%" }}
        label="Nombre" error={arrError[1]}
        defaultValue="Nombre"
        value={nombre}
        name="nombre"
        size="small" type="text"
        autoComplete="off"
        variant="outlined"
      />
    </Box>
  )

  const Paso3 = (
    !cargandoNom ?<div>
      <Box display="flex"  flexDirection="column">
      {!aviso  &&  alertaGeneral("Seleccione Género.")}  
      <Box display="flex" justifyContent={"center"}  >
        <FormControl component="fieldset" size="small" style={{ margin: 0 + 'rem',padding:0 }} 
          required error={arrError[2]}
        >
          <FormLabel component="legend" style={{ fontSize: .9 + 'rem' }} size="small">Género</FormLabel>
          <RadioGroup row aria-label="genero" size="small"   name="genero"  value={genero} onChange={handleChange}>
            <FormControlLabel
              value="H" size="small"  
              control={<Radio size="small" color="primary" />}
              label="Hombre" //labelPlacement="start"
            />
            <FormControlLabel
              value="M" size="small"  
              control={<Radio size="small" color="primary" />}
              label="Mujer" labelPlacement="start"
            />
          </RadioGroup>
        </FormControl>
      </Box>
      </Box>
    </div>
     :<Box><LinearProgress/></Box>
  )

  const Paso4 = (
    <div>
      <Box display="flex" flexDirection={"column"}>
      {!aviso  && alertaGeneral("Escriba su Teléfono.")}  
      <Hidden smUp>
        <Box display="flex" justifyContent={"center"}  >
        <TextField
          id="tel-input02" onChange={onChangeNumTel}
          label="Teléfono" defaultValue="Tel"
          value={tel} name="tel" size="small" type="tel"
          inputProps={{ maxlength: 10 }} style={{ width:75+'%'}}
          autoComplete="off" required error={arrError[3]}
          variant="outlined"
        /> 
        </Box>
        </Hidden>  
        <Hidden xsDown>
        <Box display="flex" justifyContent={"center"}  >
        <TextField
          id="tel-input03" onChange={onChangeNumTel}
          label="Teléfono" defaultValue="Tel"
          value={tel} name="tel" size="small" type="tel"
          inputProps={{ maxlength: 10 }} style={{ width:60+'%'}}
          autoComplete="off" required error={arrError[3]}
          variant="outlined"
        /> 
        </Box>
        </Hidden>  
      </Box>
    </div>
  )

  const Paso5 = (
    <Box display="flex" justifyContent={"center"} flexDirection={"column"}>
      {!aviso  && alertaGeneral("Escriba su Email.")} 
      <TextField
        id="email-input02" onChange={onChangeCorreo}
        style={{ width: "100%" }}
        label="Email" value={email}
        name="email" size="small" variant="outlined"
        autoComplete="off" required error={arrError[4]}
      />
    </Box>
  )

  const Paso6 = (
    <Box>
      {!aviso  && alertaGeneral("Escriba su comentario o sugerencia (mínimo 50 caracteres).")} 
      <TextField
        id="sugerencia-input"
        onChange={onChangeSug}
        style={{ width: 100 + "%" }}
        label="Sugerencia" minRows={2}
        value={sugerencia} name="sugerencia" required error={arrError[5]}
        size="small" multiline
        variant="outlined" autoComplete="off"
      />
      <Box display={"flex"} justifyContent="flex-end" >
        <Typography variant="body" color="initial" >{sugerencia.length}</Typography>
      </Box>
      <TextField
        id="pregunta1-input" onChange={onChangeSug} style={{ width: 100 + "%" }}
        label="¿Cuál es el principal problema que hoy enfrenta el EdoMéx?" minRows={2}
        value={pregunta1} name="pregunta1" required //error={arrError[5]}
        size="small" multiline variant="outlined" autoComplete="off"
      />
      <Box display={"flex"} justifyContent="flex-end" >
        <Typography variant="body" color="initial" >{pregunta1.length}</Typography>
      </Box>
      <TextField
        id="pregunta2-input" onChange={onChangeSug} style={{ width: 100 + "%" }}
        label="¿Cuál es el principal problema de tu municipio?" minRows={2}
        value={pregunta2} name="pregunta2" required //error={arrError[5]}
        size="small" multiline variant="outlined" autoComplete="off"
      />
      <Box display={"flex"} justifyContent="flex-end" >
        <Typography variant="body" color="initial" >{pregunta2.length}</Typography>
      </Box>
      <TextField
        id="pregunta3-input" onChange={onChangeSug} style={{ width: 100 + "%" }}
        label="¿Cuál es el principal problema de tu colonia? " minRows={2}
        value={pregunta3} name="pregunta3" required //error={arrError[5]}
        size="small" multiline variant="outlined" autoComplete="off"
      />
      <Box display={"flex"} justifyContent="flex-end" >
        <Typography variant="body" color="initial" >{pregunta3.length}</Typography>
      </Box>
    </Box>
  )
  async function llamadaApiCarga(data, url, miFuncion,setCarga) {
    setCarga(true)
    let config = {
      method: "post",
      url: process.env.REACT_APP_ENLACE_PAG + url,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
      cancelToken: source.token,
    };
    await axios(config)
      .then(function (response) {
        let auxiliar = response.data;
        miFuncion(auxiliar);            
      })
      .catch(function (error) {
        console.log(error);
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.mensaje === "Datos Incorrectos"
        ) {
          console.log("datos incorrectos");
        }
      });
      setCarga(false)
  }

  function contenidoStep(step) {
    switch (step) {
      case 0:
        return Paso1;
      case 1:
        return Paso2;
      case 2:
        return Paso3;
      case 3:
        return Paso4;
      case 4:
        return Paso5;
      case 5:
        return Paso6;

      default:
        return 'Paso sin contenido';
    }
  }

  function limpiarAlerta(){
    setMensaje(' ');
    setTipoAdvertencia("warning")  
    setAviso(false);
  }
  const VerificaNombre = () => {
    let Dispvo = localStorage.getItem("Dispvo");
    let data = qs.stringify({
        cadena:nombre.trim(),
        idDispositivo: Dispvo,
    });
    let url = "perfil-verifica-cadena";
    llamadaApiCarga(data, url, respuestaNombre,setCargandoNom);
  };

  function respuestaNombre(auxiliar){        
    const isEmpty = Object.keys(auxiliar).length === 0                
    let auxArr=arrError
    if (isEmpty || auxiliar.coincidencia===0 ||  auxiliar.coincidencia==="0"   ) 
    {      
        limpiarAlerta()
        auxArr[1]=false          
    } 
    else 
    {   let mensaje = "Nombre invalido, ingrese un Nombre diferente.";
        toast.warning(mensaje,{
            pauseOnFocusLoss: false,                    
            toastId: `nvo-perfil${5}`
        })  
        handleBack()          
        mandaAlerta(mensaje, "warning");
        auxArr=arrError
        auxArr[1]=true                    
        setArrError(auxArr) 
        setBtnDeshabilitado(false)
    }
}

  function validaPasoGeneral(auxValido,num) {
    let respuesta = false
    let auxArr=arrError    
    if (auxValido ) {
      if (num===1) 
      {
        VerificaNombre(num)   
      }
      respuesta = true
      limpiarAlerta()
      auxArr[num]=false
    }
    else{            
      auxArr[num]=true            
    }   
    setArrError(auxArr)  
    return respuesta
  }   

  function validaPasos(e) {
    let respuesta = false
    // console.log("validando  modal");        
    switch (e) {
      case 0:
        respuesta = validaPasoGeneral(!verificaSelect(Municipio, "un Municipio"),0)//validaParte1()
        break
      case 1:
        respuesta = validaPasoGeneral(validaVariable(nombre, 'Nombre', 7),1)//validaParte2()
        break
      case 2:
        respuesta = validaPasoGeneral( !verificaSelect(genero, " Genero"),2)
        break
      case 3:
        respuesta =validaPasoGeneral(validaVariable(tel, 'Teléfono', 10),3)// validaParte4()
        break
      case 4:
        respuesta = true //validaPasoGeneral(validaVariable(tel, 'Teléfono', 10),3) validaParte5()
        break
      case 5:
        respuesta =validaPasoGeneral(validaVariable(sugerencia, 'Sugerencia'),5)// validaParte6()
        break
      default:
        break
    }
    return respuesta
  }

  function getSteps() {
    return ['', '', '', '', '', ''];
  } 
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Modal
      contenidoStep={contenidoStep} getSteps={getSteps}
      titulo="Contactame" modalAbierto={modalAbierto}
      setModalAbierto={setModalAbierto}
      guardarDatos={guardarDatos} mensaje={mensaje}
      tipoAdvertencia={tipoAdvertencia} aviso={aviso}
      btnDeshabilitado={btnDeshabilitado}
      setAviso={setAviso}
      tamanio={'md'} cargando={espera}
      mostraMensaje={mostraMensaje}
      validaPasos={validaPasos}
      activeStep={activeStep} setActiveStep={setActiveStep}      
//      btnVerCerrar={verCerrar}
    >

    </Modal>
  );
};

export default SugerenciaModal;
