import React from "react";
import { Box, Hidden } from "@material-ui/core";
import VentanaMovil from "./VentanaMovil";
//import VentanaWeb from "./VentanaWeb";
import VentanaWebFB from './VentanaWebFB'
//import VentanaWeb2 from "./VentanaWeb2";
import injectFbSDKScript from "../fb-share/sdk-fb";
import { authUser } from '../funciones/AuthUser'
import {AuthContext} from '../context/AuthContext'
import { useHistory } from "react-router-dom";
import {GeneralesContext} from '../context/GeneralesContext'
const Pantalla = () => {
  let history = useHistory();   
  const {usuario,guardarUsuario}=React.useContext(AuthContext);
  const {generales,guardarGenerales}=React.useContext(GeneralesContext); 
  const[bandera,setBandera]=React.useState(false)
  React.useEffect(()=>{
    console.log("regresando");
    const usuID = localStorage.getItem('UsuId');
    const usu = localStorage.getItem('UsuarioNom');
    const nvl= localStorage.getItem('Tipo');        
    let auxValidacion=authUser()
    const token = localStorage.getItem("token20")
    if(  !auxValidacion  )
    {   guardarUsuario([])				
        guardarGenerales([])  
       //console.log(auxValidacion,token,"entrando1");  
        localStorage.clear();            	             
        history.push("/")	                	
    }
    else
    {   
      if (usuario.length===0) 
      {console.log("entrando2");  
          guardarUsuario({Nombre:usu,nivel:nvl});                                         
          
      }     
      setBandera(true)                                                               
    }   
  },[])
  return (
    <div>
       {bandera?<span>
      <Hidden smUp> {/* Cuando es movil */}
        <Box>
          <VentanaMovil />
        </Box>
      </Hidden>
      <Hidden xsDown>
      
        <Box   >
        <div id="fb-root"></div>
      {injectFbSDKScript()}
          {/* 
          <VentanaWeb/>
          */}
          <VentanaWebFB /> 
        </Box>
      </Hidden>
      </span>
      :null}
    </div>
  );
};

export default Pantalla;
