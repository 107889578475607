import React from "react";
import { Box, Hidden, IconButton} from "@material-ui/core";
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
const NuevoEnlace=({link,children})=>{
    //console.log(link);
    return(
    <a href={link} target="popup" 
      onClick={`window.open(${link},'popup','width=600,height=600'); return false;`}
    >
      {children}
    </a>)
  }
 /*  const TamMovil=(ancho,anchoIcon,altoIcon)=>{
return(<Box style={{ width: 100 + "%" }}>
<NuevoEnlace link={"https://www.facebook.com/RedMorenaNacional"}>
  <IconButton style={{ width: ancho + "px" }}>
    <FacebookIcon
      style={{ color: "white", width: anchoIcon + "px", height: altoIcon + "px" }}
    />
  </IconButton>
</NuevoEnlace>
<NuevoEnlace link={"https://www.instagram.com/dgadigital"}>
  <IconButton style={{ width: ancho + "px" }}>
    <InstagramIcon
      style={{ color: "white", width: anchoIcon + "px", height: altoIcon + "px" }}
    />
  </IconButton>
</NuevoEnlace>
<NuevoEnlace link={"https://twitter.com/dgadigital"}>
  <IconButton style={{ width: ancho + "px" }}>
    <TwitterIcon
      style={{ color: "white", width: anchoIcon + "px", height: altoIcon+ "px" }}
    />
  </IconButton>
</NuevoEnlace> 
</Box>)
  } */ 
  const TamMovil2=(ancho,anchoIcon,altoIcon)=>{
    return(<Box style={{ width: 100 + "%" }}>    
      <IconButton style={{ width: ancho + "px" }}>
        <FacebookIcon
          style={{ color: "white", width: anchoIcon + "px", height: altoIcon + "px" }}
        />
      </IconButton>     
      <IconButton style={{ width: ancho + "px" }}>
        <InstagramIcon
          style={{ color: "white", width: anchoIcon + "px", height: altoIcon + "px" }}
        />
      </IconButton>        
      <IconButton style={{ width: ancho + "px" }}>
        <TwitterIcon
          style={{ color: "white", width: anchoIcon + "px", height: altoIcon+ "px" }}
        />
      </IconButton>    
    </Box>)
      } 

  const Botones=()=>{}
  const BotonesContacto = () => {
    return (
      <span>
        <Hidden smUp> {/* Cuando es movil */}
          {TamMovil2(40,35,35)}
        </Hidden>
        <Hidden xsDown>
          {TamMovil2(60,50,50)}
        </Hidden>
      </span>
    );
  };
  export default BotonesContacto